import React, { useEffect, useState } from "react";
import { Button, Card, Col, Layout, Row, Skeleton } from "antd";
import { UserSwitchOutlined } from "@ant-design/icons";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { DoneLessonCard } from "../PlannerPage/DoneLessonCard";
import { InstructorCard } from "../PlannerPage/InstructorCard";
import { MissedLessonCard } from "../PlannerPage/MissedLessonCard";
import { UpcomingLessonCard } from "../PlannerPage/UpcomingLessonCard";
import { Notification } from "../PlannerPage/Notification";
import { http } from "../../helpers/http";
//@ts-ignore
import _ from 'lodash';
import { TopInfoCard } from "../PlannerPage/TopInfoCard";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CheckpointsCard } from "../StudentCheckpoints/CheckpointsCard";
import i18next from "i18next";
import { DrivingStats } from "./DrivingStats";
import { DrivingHistory } from "./DrivingHistory";
import { DrivingSummary } from "./Summary/DrivingSummary";
import { TopicsCard } from "./TopicsSection/TopicsCard";
import { ShortNotificationsCard } from "../Notifications/ShortNotificationsCard";
// import { InstructorCard } from "../Groups/AddInstructor";

export const DrivingPage = (props: any) => {
    const { t } = useTranslation();
    const { self: student } = props;
    const history = useHistory();
    const [fullEval, setFullEval] = useState(false);
    const [lessonInfo, setLessonInfo] = useState({ last_instructor: null, lessons: null, last_lesson: null } as any);
    // const [cities, setCities] = useState([] as any);
    // const [lastService, setLastService] = useState({} as any);
    // const [lastLesson, setLastLesson] = useState({} as any);
    // const [pastLessons, setPastLessons] = useState([] as any);
    const [loading, setLoading] = useState(true);
    const [studentExamInfo, setStudentExamInfo] = useState({ id: -1 });
    const [notifications, setNotifications] = useState([] as any);
    const [customNotifications, setCustomNotifications] = useState([] as any);
    const [isMobile, setIsMobile] = useState(false);
    const [gradeLVL, setGradeLVL] = useState("beginner");
    const [subjects, setSubjects] = useState([] as any);
    const [studentRating, setStudentRating] = useState(0);
    const [subjectHistory, setSubjectHistory] = useState({} as any);
    const [studentHistory, setStudentHistory] = useState([] as any);
    const [themes, setThemes] = useState([] as any);
    const [examSubjectHistory, setExamSubjectHistory] = useState({} as any);
    const [studentExamHistory, setStudentExamHistory] = useState([] as any);
    const [examSubjects, setExamSubjects] = useState([] as any);
    const [studentTestDriveHistory, setStudentTestDriveHistory] = useState([] as any);
    const [notificationsLoading, setNotificationsLoading] = useState(false);

    const fetchNotifications = async () => {
        setNotificationsLoading(true);
        await http.get('notifications-unread').then((response) => {
            setNotifications(response.data);
        })
        await http.get('get-custom-notifications').then((response) => {
            //console.log(response.data)
            setCustomNotifications(response.data.data);
        })
        setNotificationsLoading(false);
    };

    const getTestDriveHistory = () => {
        http.get(`get-student-test-drive-evaluation-history/${student.id}`).then((res: any) => {
            console.log(res)
            setStudentTestDriveHistory(res.data.data);
        })
    }

    const getStudentEvalHistory = () => {
        // http.get(`get-student-driving-evaluation-history/${student.id}?sql=1`)
        http.get(`get-student-driving-evaluation-history/${student.id}`).then((res: any) => {
            console.log(res)
            setStudentHistory(res.data.data);
            setSubjectHistory(res.data.theme_subject_history);

            http.get(`get-student-driving-exam-evaluation-history/${student.id}`).then((response: any) => {
                setStudentExamHistory(response.data.data);
                setExamSubjectHistory(response.data.theme_subject_history);
                if (response.data.data.length > 0) {
                    setGradeLVL("exam_worthy");
                    return;
                }
                const copyData = JSON.parse(JSON.stringify(res.data.data));
                const lastEvaluatedLesson = copyData.reverse().find((el: any) => {
                    return el?.status.includes("done") && el?.grade;
                });
                console.log(lastEvaluatedLesson)
                setGradeLVL(
                    lastEvaluatedLesson
                        ? lastEvaluatedLesson.grade
                        : "beginner"
                )
            })
        })
    };

    // const getExamEvalHistory = () => {
    //     http.get(`get-student-driving-exam-evaluation-history/${student.id}`).then((res: any) => {
    //         console.log(res)
    //         setStudentExamHistory(res.data.data);
    //         setExamSubjectHistory(res.data.theme_subject_history);
    //     })
    // };

    const getStudentEvalRating = () => {
        http.get(`get-student-driving-evaluation-rating/${student.id}`).then((res: any) => {
            console.log(res)
            setStudentRating(res.data)
        })
    };

    const fetchSubjects = () => {
        http.get(`driving-theme-subjects`).then((res: any) => {
            console.log(res)
            setSubjects(res.data.data);
        })
    };

    const fetchExamSubjects = () => {
        http.get(`driving-exam-theme-subjects`).then((res: any) => {
            console.log(res)
            setExamSubjects(res.data.data);
        })
    };

    const fetchQuestions = () => {
        http.get(`driving-themes`).then((res: any) => {
            setThemes(res.data.data);
        })
    }

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            setFullEval(!!student.category.full_eval)
            getStudentEvalHistory();
            getTestDriveHistory();
            getStudentEvalRating();
            fetchSubjects();
            fetchExamSubjects();
            fetchQuestions();
        }
    }, [student])

    const fetchLessons = async () => {
        await http.get('lesson_info', { params: { category: student.category_id } })
            .then(result => {
                const { data } = result.data;
                const newLessons = data.lessons?.filter((lesson: any) => {
                    let upcLesson = (lesson.status === 'new' || lesson.status === 'test_drive')
                        && lesson.exam === 0
                        && moment(lesson.date, "YYYY-MM-DD").isSameOrAfter(moment().subtract(1, "day"))
                        || lesson.status === 'test_drive'
                    if (moment(lesson.date, "YYYY-MM-DD").isSame(moment(), 'day')) {
                        upcLesson = moment(lesson.time_from, "HH:mm:ss").isSameOrAfter(moment(), "hour")
                            && (lesson.status === 'new' || lesson.status === 'test_drive')
                            && lesson.exam === 0
                    }
                    return upcLesson
                });
                const doneLessons = data.lessons?.filter((lesson: any) => {
                    return lesson.status === 'done' || lesson.status === "exam_failed" || lesson.status === "exam_passed";
                });
                const canRetryExam = doneLessons?.filter((lesson: any) => {
                    return lesson.status === "exam_failed";
                }).length
                const lessonsMissed = data.lessons?.filter((lesson: any) => {
                    return lesson.status !== "new" && lesson.status !== "done" && lesson.status !== "exam_failed" && lesson.status !== "exam_passed" && lesson.status !== "exam";
                });
                const examSlot = data.lessons?.filter((slot: any) => {
                    return slot.status === "exam";
                });
                const upcomingLessons = newLessons.filter((lesson: any) => {
                    return moment(lesson.date, "YYYY-MM-DD").isSameOrAfter(moment().subtract(1, "day"));
                });
                const nextLesson = upcomingLessons.length > 0
                    ? upcomingLessons[0]
                    : undefined
                setLessonInfo({
                    ...data,
                    lessons: data.lessons,
                    newLessons,
                    doneLessons,
                    lessonsMissed,
                    examSlot,
                    nextLesson,
                    canRetryExam
                });
                http.get(`/student-progress/has-exam/${student.id}`).then((resp: any) => {
                    if (resp.data === "") {
                        setStudentExamInfo({ id: 0 });
                    } else {
                        setStudentExamInfo(resp.data);
                    }
                })
            });
    }

    function removeLesson() {
        fetchLessons();
        props.refreshBalance();
    }

    function goToCalendar(id: any, city_id: any = lessonInfo.last_lesson?.city_id) {
        history.push(`/planner/calendar/${id}`, { city_id });
    }

    const deleteNotification = (id: any, custom: boolean = false) => {
        if (custom) {
            setCustomNotifications((data: any) => {
                const newList = data.filter((item: any) => {
                    return item.id !== id;
                })
                return newList;
            })
            return;
        }
        setNotifications((data: any) => {
            const newList = data.filter((item: any) => {
                return item.id !== id;
            })
            return newList;
        })
    }


    useEffect(() => {
        setIsMobile(window.matchMedia("(max-width: 767px)").matches)

        window.addEventListener("resize", () => {
            setIsMobile(window.matchMedia("(max-width: 767px)").matches)
        });
    }, []);

    useEffect(() => {
        if (Object.keys(student).length > 0) {
            fetchLessons().then(() => {
                setLoading(false);
            });
        }
    }, [student]);

    useEffect(() => {
        fetchNotifications();
    }, [i18next.language])

    return (loading
        ? <Layout className='skeleton-main-layout' style={{ height: "100vh", width: "100vw" }}><Skeleton /></Layout>
        : <Layout className="main-content">
            <Row style={{ marginTop: 20 }} gutter={20}>
                <Col span={24}>
                    {
                        (customNotifications.length > 0 || notifications.length > 0)
                        && <ShortNotificationsCard
                            refreshBalance={() => props.refreshBalance()}
                            notifications={notifications}
                            customNotifications={customNotifications}
                            fetchNotifications={fetchNotifications}
                            loading={notificationsLoading}
                            student={student}
                        />
                    }
                    {/* {
                        notifications.length > 0 || customNotifications.length > 0
                            ? <Card style={{ width: "100%", marginBottom: 20 }} title={t('common.notifications')}>
                                {
                                    customNotifications?.map((notific: any) => {
                                        return <Notification
                                            key={notific.id}
                                            deleteFromList={deleteNotification}
                                            notification={notific}
                                            refreshBalance={() => props.refreshBalance()}
                                            custom
                                        />
                                    })
                                }
                                {notifications?.map((item: any) => {
                                    return <Notification
                                        key={item.id}
                                        deleteFromList={deleteNotification}
                                        notification={item}
                                        refreshBalance={() => props.refreshBalance()}
                                    />
                                })}
                            </Card>
                            : <></>
                    } */}
                </Col>
            </Row>
            <Switch>
                <Route path="/driving/summary">
                    <DrivingSummary
                        self={student}
                        upcomingLessons={lessonInfo.newLessons}
                        examSlot={lessonInfo.examSlot}
                        lastInstructor={lessonInfo.last_instructor}
                        lastLesson={lessonInfo.last_lesson}
                        student={student}
                        loading={loading}
                        goToCalendar={goToCalendar}
                        isMobile={isMobile}
                        studentHistory={studentHistory}
                        studentExamHistory={studentExamHistory}
                        studentRating={studentRating}
                        subjects={subjects}
                        examSubjects={examSubjects}
                        removeLesson={removeLesson}
                        gradeLVL={gradeLVL}
                        studentTestDriveHistory={studentTestDriveHistory}
                    />
                </Route>
                <Route path={`/driving/stats/:lessonID/exam`}>
                    <DrivingStats
                        subjects={examSubjects}
                        isMobile={isMobile}
                        studentHistory={studentExamHistory}
                        exam
                    />
                </Route>
                <Route path={`/driving/stats/:lessonID/testDrive`}>
                    <DrivingStats
                        subjects={examSubjects}
                        isMobile={isMobile}
                        studentHistory={studentTestDriveHistory}
                        testDrive
                    />
                </Route>
                <Route path={`/driving/stats/:lessonID`}>
                    <DrivingStats
                        subjects={subjects}
                        isMobile={isMobile}
                        studentHistory={studentHistory}
                    />
                </Route>
                <Route path="/driving/stats">
                    {
                        student.category.full_eval
                            ? <TopicsCard
                                student={student}
                                isMobile={isMobile}
                                studentHistory={studentHistory}
                                subjectHistory={subjectHistory}
                                themes={themes}
                            />
                            : <></>
                    }
                </Route>
                <Route path="/driving/history">
                    <DrivingHistory
                        self={student}
                        isMobile={isMobile}
                        studentHistory={studentHistory}
                        subjects={subjects}
                        studentExamHistory={studentExamHistory}
                        examSubjects={examSubjects}
                        studentTestDriveHistory={studentTestDriveHistory}
                    />
                </Route>
            </Switch>
            <Row style={{ marginTop: 20 }} gutter={20}>
                {/* <Col span={24}>
                    <CheckpointsCard self={student} />
                </Col> */}
                {/* <Col span={24}>
                    <Button onClick={() => console.log(instructor)}>MP</Button>
                    <TopInfoCard
                        exam={studentExamInfo}
                        canRetryExam={lessonInfo?.canRetryExam}
                        examSlot={lessonInfo?.examSlot.length > 0 && lessonInfo?.examSlot[0]}
                        slot={lessonInfo?.nextLesson}
                        lastLesson={lessonInfo?.last_lesson}
                        toCalendar={() => goToCalendar(lessonInfo?.last_lesson?.instructor_service_id)}
                        city={lessonInfo?.last_lesson?.city_id}
                    />
                </Col> */}
                {/* <Col span={24}>
                    <Card
                        className='responsive-card'
                        style={{ marginBottom: 20 }}
                        title={
                            <Row justify='space-between'>
                                <span style={{ fontWeight: "bold", whiteSpace: "normal" }}>{student?.category?.value} {t('driving.category_driving_learning')}</span>
                                <span style={{ color: "#777" }}></span>
                                {
                                    lessonInfo.last_lesson
                                        ? <Button onClick={() => goToCalendar(lessonInfo.last_lesson?.instructor_service_id, lessonInfo.last_lesson?.city_id)} type="primary">{t('driving.apply_for_lesson')}</Button>
                                        : <Button onClick={() => history.push('/planner/select-instructor')} type="primary">{t('driving.select_instructor')}</Button>
                                }
                            </Row>
                        }>
                        {
                            lessonInfo?.examSlot?.length > 0
                                ? <>  <div style={{ fontSize: "16px", marginBottom: 5 }}>
                                    {t('driving.exam')} ({lessonInfo.examSlot.length})
                                </div>
                                    <div style={{ marginBottom: 20 }}>
                                        {lessonInfo.examSlot.map((lesson: any) => {
                                            return <UpcomingLessonCard
                                                key={`upc-${lesson.slot_id}`}
                                                lesson={lesson}
                                                removeLesson={removeLesson}
                                            />
                                        })}
                                    </div></>
                                : <></>
                        }
                        <div style={{ fontSize: "16px", marginBottom: 5 }}>
                            {t('driving.scheduled_lessons')} ({lessonInfo.newLessons.length})
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            {lessonInfo.newLessons.map((lesson: any) => {
                                return <UpcomingLessonCard
                                    key={`upc-${lesson.slot_id}`}
                                    lesson={lesson}
                                    removeLesson={removeLesson}
                                />
                            })}
                        </div>
                        <div style={{ fontSize: "16px", marginBottom: 5 }}>
                            {t('driving.attended_lessons')} ({lessonInfo.doneLessons.length})
                        </div>
                        <div>
                            {lessonInfo.doneLessons.map((lesson: any) => {
                                return <DoneLessonCard
                                    key={`donelesson-${lesson.slot_id}`}
                                    lesson={lesson}
                                />
                            })}
                        </div>

                        <div style={{ fontSize: "16px", marginBottom: 5 }}>
                            {t('driving.missed_lessons')} ({lessonInfo.lessonsMissed.length})
                        </div>
                        <div>
                            {lessonInfo.lessonsMissed.map((lesson: any) => {
                                return <MissedLessonCard
                                    key={`missedlesson-${lesson.slot_id}`}
                                    lesson={lesson}
                                />
                            })}
                        </div>
                    </Card>
                </Col> */}
            </Row>
            {/* <Row style={{}} gutter={20}>
                <Col span={24}>
                    <Card
                        className='responsive-card'
                        style={{ marginBottom: 20 }}
                        title={
                            <div>
                                {t('driving.my_instructor')}
                            </div>
                        }>
                        <Row>
                            <Col span={24}>
                                {lessonInfo.last_instructor !== null
                                    ? <InstructorCard
                                        callback={goToCalendar}
                                        key={`instructor-card-${lessonInfo.last_instructor.instructor_id}`}
                                        instructor={lessonInfo.last_instructor}
                                        filter={lessonInfo.last_lesson !== undefined
                                            ? lessonInfo.last_lesson
                                            : student
                                        }
                                        type="inline-small"
                                        loading={loading}

                                    />
                                    : <></>}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                            <Col span={24}>
                                <Link to="/planner/select-instructor">
                                    <Button
                                        icon={<UserSwitchOutlined />}
                                        type="primary"
                                        style={{ whiteSpace: "normal" }}
                                    >
                                        {lessonInfo.last_instructor != null ? t('driving.apply_for_lesson_to_other_instructor') : t('driving.select_instructor')}
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row> */}
        </Layout>
    )
}