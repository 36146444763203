import React from 'react';
import './App.scss';
import './AppResponsive.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    matchPath,
} from "react-router-dom";

import { Button, Card, Divider, Layout, Menu, Statistic, Spin, ConfigProvider, Modal, Skeleton, Row } from 'antd';
import { SettingOutlined } from "@ant-design/icons";
import { http } from './helpers/http';
import { PlannerPage } from './pages/PlannerPage';
import { LoginPage } from './pages/LoginPage';
import { StudentPaymentsPage } from './pages/PaymentsPage';
import Sider from 'antd/lib/layout/Sider';
import { BalanceCard } from './pages/common/BalanceCard';
import { ReactComponent as CalendarIcon } from './img/ic-sidenav-calendar.svg';
import { ReactComponent as PaymentsIcon } from './img/ic-sidenav-payments.svg';
import { ReactComponent as TheoryIcon } from './img/ic-sidenav-online.svg';
import { ReactComponent as SteeringIcon } from './img/ic-sidenav-steering.svg';
import { ReactComponent as CalendarSelectedIcon } from './img/ic-sidenav-calendar-selected.svg';
import { ReactComponent as PaymentsSelectedIcon } from './img/ic-sidenav-payments-selected.svg';
import { ReactComponent as TheorySelectedIcon } from './img/ic-sidenav-online-selected.svg';
import { ReactComponent as SteeringSelectedIcon } from './img/ic-sidenav-steering-selected.svg';
import { ReactComponent as NotificationsIcon } from './img/ic-sidebar-notifications.svg';
import { ReactComponent as NotificationsSelectedIcon } from './img/ic-notifications.svg';
import { Agreements } from './pages/LoginPage/Agreements';
import moment from 'moment';
import { ExtraServicesMain } from './pages/ExtraServices/ExtraServicesMain';
import { ELearningLayout } from './pages/e-learning/ELearningLayout';
import { LessonLayout } from './pages/e-learning/LessonLayout';
import buildJson from "./build.json";
import { BookmarksView } from './pages/e-learning/BookmarksView';
import { ExamStartPage } from './pages/e-learning/ExamStartPage';
import { ExamPage } from './pages/e-learning/ExamPage';
import { TheoryMain } from './pages/Theory/TheoryMain';
import { DrivingPage } from './pages/Driving/DrivingPage';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import lv_LV from 'antd/lib/locale/lv_LV';
import en_GB from 'antd/lib/locale/en_GB';
import ru_RU from 'antd/lib/locale/ru_RU';
// import lv from './locale/lv.json';
// import en from './locale/en.json';
// import ru from './locale/ru.json';
//@ts-ignore
import Flag from "react-flags";
import { NotificationSettings } from './pages/Settings/NotificationSettings';
import { TheoryExamsPlanner } from './pages/TheoryExams/TheoryExamsPlanner';
import { TutorialModal } from './pages/Tutorial/TutorialModal';
import { AxiosResponse } from 'axios';
import { NotificationsLayout } from './pages/Notifications/NotificationsLayout';
import { MandatoryDataModal } from './pages/Notifications/MandatoryDataModal';
import { CoinsLayout } from './pages/Coins/CoinsLayout';
import { TheoryExamStartPage } from './pages/TheoryExams/TheoryExam/TheoryExamStartPage';
import { TheoryExamPage } from './pages/TheoryExams/TheoryExam/TheoryExamPage';
import { MyQuestions } from './pages/e-learning/MyQuestions';
import { StartTestPage } from './pages/CSNTests/StartTestPage';
import { TestsMainPageLayout } from './pages/CSNTests/TestsMainPageLayout';
import { TestInstancePage } from './pages/CSNTests/TestInstancePage';
import { ReactComponent as IcStar } from "../src/img/small_star_new.svg";
import { BannerModal } from './pages/components/BannerModal';
import { WebCsnTestsDemo } from './pages/CSNTests/WebCsnTestsDemo';
import { ContactsScreen } from './pages/Contacts/ContactsScreen';
import GetItOnGooglePlay from './img/GetItOnGooglePlay.png';
import GetItOnAppStore from './img/appStoreBadge.svg';
class App extends React.Component<any, {
    login: boolean,
    loaded: boolean,
    windowHeight: number,
    self: any,
    group: any,
    agreement: any,
    checkForAgreement: boolean,
    hideAgreements: boolean,
    showSider: boolean,
    eLearning: boolean,
    hasTheory: boolean,
    languages: any[],
    changeLangInELearning: any,
    balance: any,
    credit: any,
    loginError: boolean,
    categories: any[],
    studentCategories: any[],
    currentCategory: number,
    real_elearning: boolean,
    isMobile: boolean,
    categoryIsReady: boolean,
    bonus: any
}> {
    constructor(props: {}) {
        super(props);

        this.state = {
            windowHeight: window.innerHeight,
            login: false,
            loaded: false,
            self: {} as any,
            group: null,
            agreement: null as any,
            checkForAgreement: false,
            hideAgreements: false,
            showSider: true,
            eLearning: false,
            hasTheory: false,
            languages: [],
            changeLangInELearning: {
                lang: "lv",
                modalVisible: false,
                confirmed: false
            },
            balance: null,
            credit: null,
            loginError: false,
            categories: [],
            studentCategories: [],
            currentCategory: 1,
            real_elearning: false,
            isMobile: false,
            categoryIsReady: true,
            bonus: 0
        };
    }

    locales = {
        lv: lv_LV,
        en: en_GB,
        ru: ru_RU
    } as any;

    handleWindowResize = () => {
        this.setState({ windowHeight: window.innerHeight });
    }

    checkCache = async () => {
        if (Number(process.env.REACT_APP_DEV) === 1) return;
        http.get('build-time?app=student').then((response: any) => {
            //console.log(response.data.build_time, buildJson.buildTime)
            if (!(response.data.build_time === buildJson.buildTime)) {
                window.location.href = window.location.href;
                //console.log("Clearing cache")
                window.location.reload();
            }
        })
    };

    fetchCategories = () => {
        http.get(`categories`).then((res: any) => {
            this.setState({ categories: res.data.data })
        })
    };

    setActiveCategory(category_id: any): void {
        http.post(`switch-student-category/${category_id}`).then((res: any) => {
            window.location.href = "/";
            ;
        })
    }

    async componentDidMount() {
        this.checkCache();
        window.addEventListener('resize', this.handleWindowResize);

        const sp = new URLSearchParams(window.location.search);
        const savedToken = sp.get("bt");
        if (savedToken != null) {
            sessionStorage.setItem("bearer_token", savedToken);
            window.location.href = "/";
        }

        const token = sessionStorage.getItem("bearer_token");
        if (token == null) {
            this.setState({ login: true, loaded: true });
        } else {
            http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            this.getSelf();
            this.setState({ isMobile: window.matchMedia("(max-width: 991px)").matches });
        }


    }

    componentDidUpdate = (prevProps: any, prevState: any) => {
        // console.log(this.state.changeLangInELearning)
        // if (this.state.changeLangInELearning.confirmed) this.changeLang(this.state.changeLangInELearning.lang)
        if (prevState.checkForAgreement !== this.state.checkForAgreement) {
            if (this.state.checkForAgreement) {
                this.checkForAgreements();
            }
        }
        // if (prevState.group !== this.state.group) {
        //     const { self } = this.state
        //     const stGroup = self.only_study_group[self.only_study_group.length - 1]
        //     const showAgreements = stGroup.id !== Number(process.env.REACT_APP_NO_GROUP_ID) && moment(stGroup?.start_date, "YYYY-MM-DD").isAfter(moment("2022-03-14", "YYYY-MM-DD"))

        //     this.setState({ hideAgreements: !showAgreements });
        // };
        // console.log(this.state.login)
        if (prevState.loaded !== this.state.loaded && this.state.loaded && !this.state.login) {
            this.getLanguages();
            // microApi.get('validate_user').then(() => {
            //     this.getLanguages();
            // })
        }
        // if (prevState.loaded !== this.state.loaded && this.state.loaded && !this.state.login)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    logout = (e: any) => {
        e.preventDefault();
        sessionStorage.clear();
        window.location.href = "/";
    }

    getSelf = async () => {
        this.fetchBalance();
        await http.get('/self').then((response: any) => {
            this.fetchCategories();
            http.get(`get-student-categories`).then((res: any) => {
                this.setState({
                    currentCategory: res.data.current_category,
                    studentCategories: res.data.data
                })
            })
            const { data } = response.data;
            const activeCategory = data.categories.find((cat: any) => cat.category_id === data.category_id)
            const active_group = data.only_study_group.find((el: any) => el.id === activeCategory?.study_group_id);
            const categoryIsReady =
                (active_group !== undefined && active_group.id !== Number(process.env.REACT_APP_NO_GROUP_ID))
                || (data.only_driving)
                || data?.registration_status === "theory_tests"
                || data?.registration_status === "test_drive"
                || moment(data.registered_at, "YYYY-MM-DD").isBefore(moment("2022-08-01", "YYYY-MM-DD"))
            if (active_group !== undefined) {
                const hasTheory = active_group.id !== Number(process.env.REACT_APP_NO_GROUP_ID)
                const checkForAgreement = hasTheory && !moment(active_group?.start_date, "YYYY-MM-DD").isBefore(moment("2022-08-01", "YYYY-MM-DD"));
                this.setState({
                    self: data,
                    checkForAgreement,
                    hasTheory: hasTheory,
                    eLearning: active_group?.study_type_id === 3
                        && !!active_group?.real_elearning,
                    real_elearning: !!active_group?.real_elearning,
                    group: active_group,
                    loaded: (hasTheory && moment(active_group?.start_date, "YYYY-MM-DD").isBefore(moment("2022-08-01", "YYYY-MM-DD"))) || !checkForAgreement,
                    categoryIsReady: categoryIsReady,
                })
            } else {
                this.setState({ loaded: true, self: data, categoryIsReady: categoryIsReady })
            }
            this.setLang(data?.language?.iso);
            moment.locale(data?.language?.iso);
        })
    }

    checkForAgreements = () => {
        const { self, group } = this.state;
        http.get(`/check-agreement/${self.id}${group ? `?group=${group.id}` : ""}`).then((response: any) => {
            const agreement = response.data.data;
            if (agreement !== null) {
                let allAgreements;
                let allAgreementKeys;
                if (agreement.cancelled && !agreement.approved) {
                    allAgreements = null;
                } else {
                    allAgreementKeys = Object.keys(agreement).filter((item: any) => {
                        return item.includes("agreement");
                    })
                    allAgreements = allAgreementKeys.map((key: any) => {
                        return { [key]: agreement[key] }
                    })
                }
                this.setState({
                    agreement: {
                        agreements: allAgreements,
                        approved: agreement.approved,
                        cancelled: agreement.cancelled
                    },
                    loaded: true
                });
            } else {
                this.setState({
                    agreement: {
                        agreements: null,
                        approved: null,
                        cancelled: null
                    },
                    loaded: true
                });
            }
        })
    }

    getLanguages = async () => {
        await http.get('languages').then((response: any) => {
            this.setState({ languages: response.data.data })
        })
    }

    setLang = (lang: string) => {
        i18n.changeLanguage(lang);
        // this.setActiveLanguage(lang);
        moment.locale(lang);
    }

    changeLang = (lang: string, source: string = "scripted") => {
        //console.log(!this.state.changeLangInELearning.confirmed)
        if (source === "from_button" && this.state.eLearning) {
            this.setState({
                changeLangInELearning: {
                    modalVisible: true,
                    lang: lang,
                    confirmed: false
                }
            });
            return;
        };
        this.setState({
            changeLangInELearning: {
                modalVisible: false,
                confirmed: false,
                lang: lang
            }
        });
        moment.locale(lang);
        http.post(`change-language`, { lang: lang }).then((response: any) => {
            i18n.changeLanguage(lang);
            // this.setActiveLanguage(lang);

        })
    };

    fetchBalance = () => {
        http.get('balance').then((response: any) => {
            this.setState({
                balance: response.data.data.balance,
                credit: {
                    current_credit: response.data.data.current_credit,
                    used_credit: response.data.data.used_credit,
                    credit: response.data.data.credit
                },
                bonus: response.data.data.bonus_balance
            })
        })
    }

    render() {
        const { t } = this.props;
        const { self } = this.state;
        const menuLinksPremade = [
            // { to: "/planner/payment-help", title: (<BalanceCard />) },
            { icon: (<CalendarIcon />), iconSelected: (<CalendarSelectedIcon />), to: "/planner", title: t('navigation.summary') },
            { icon: (<TheoryIcon />), iconSelected: (<TheorySelectedIcon />), to: "learn-type", title: t('navigation.e_learning') },
            {
                icon: (<SteeringIcon />), iconSelected: (<SteeringSelectedIcon />), to: "/driving", title: t('navigation.driving'), submenu: [
                    {
                        to: "/driving/summary", title: t('navigation.summary')
                    },
                    {
                        to: "/driving/stats", title: t('driving.eval.topics_and_grades')
                    },
                    {
                        to: "/driving/history", title: t('driving.eval.lessons_history')
                    }
                ]
            },
            { icon: (<PaymentsIcon />), iconSelected: (<PaymentsSelectedIcon />), to: "/agreements", title: t('navigation.agreements') },
            { icon: (<PaymentsIcon />), iconSelected: (<PaymentsSelectedIcon />), to: "/payments", title: t('navigation.payments') },
            { icon: (<PaymentsIcon />), iconSelected: (<PaymentsSelectedIcon />), to: "/extra-services", title: t('navigation.extra_services') },
            { icon: (<NotificationsIcon />), iconSelected: (<NotificationsSelectedIcon />), to: "/notifications", title: t('common.notifications') },
            {
                icon: (<PaymentsIcon />), iconSelected: (<PaymentsSelectedIcon />), to: "/tests", title: <div>
                    {t('navigation.CSN_test')}
                    <div
                        className="paragraph-xxs "
                        style={{
                            marginLeft: 8,
                            padding: "4px 8px",
                            backgroundColor: "#7434FF",
                            border: 0,
                            fontWeight: 500,
                            borderRadius: 4,
                            display: "inline-flex",
                            alignItems: "center",
                            height: 20
                        }}
                    >
                        <IcStar style={{ marginRight: 10 }} />
                        {t("common.new")}
                    </div>
                </div>
            },
            { icon: (<PaymentsIcon />), iconSelected: (<PaymentsSelectedIcon />), to: "/contacts", title: t('app.contacts') },
        ];
        const activeGroup = this.state.self?.only_study_group?.some((group: any) => group.category_id === this.state.self.category_id)
        const group = this.state.self.studygroups?.find((el: any) =>
            el.study_group_id === this.state.self?.only_study_group?.find((group: any) =>
                group.category_id === this.state.self.category_id)?.id)
        const hardReg = group?.reservation_type === "hard"
        const canStartE = moment(this.state.group?.start_date, "YYYY-MM-DD").isSameOrBefore(moment(), "day")
        const noNeedAgreement = !this.state.checkForAgreement;
        const agreementLoaded = this.state.agreement !== null;
        const haveAgreement = agreementLoaded
            ? this.state.agreement.agreements !== null
            : false
        const approvedAgreement = (haveAgreement && this.state.agreement.approved) || (haveAgreement && this.state.agreement.approved && this.state.agreement.cancelled);
        const normalLayout = (
            <ConfigProvider locale={this.locales[i18n.language]}>
                <Layout style={{ minHeight: "100vh", flexDirection: "row" }}>
                    <BannerModal />
                    {
                        !this.state.categoryIsReady
                            ? this.state.studentCategories.length === 0
                                ? <Skeleton />
                                : <div
                                    style={{
                                        backgroundColor: "#FFFFFF",
                                        width: "100%",
                                        height: "100vh",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 10,
                                        textAlign: "center"
                                    }}
                                >
                                    <h1><b>Izvēlētā kategorija vēl nav apstiprināta!</b></h1>
                                    <div>
                                        {
                                            this.state.studentCategories?.map((stCat: any) => {
                                                const active = stCat.category_id === this.state.currentCategory
                                                return <Button
                                                    className='btn ghost'
                                                    style={{
                                                        display: "inline-block",
                                                        margin: "0 5px 5px",
                                                        backgroundColor: active ? "#079dc1" : "unset"
                                                    }}
                                                    onClick={() => this.setActiveCategory(stCat.category_id)}
                                                >
                                                    {this?.state?.categories?.find((el: any) => el.id === stCat.category_id)?.value}
                                                </Button>
                                            })
                                        }
                                    </div>
                                </div>
                            : <>
                                <Modal
                                    visible={this.state.changeLangInELearning.modalVisible}
                                    onOk={() => {
                                        this.setState({
                                            changeLangInELearning: {
                                                ...this.state.changeLangInELearning,
                                                modalVisible: false,
                                                confirmed: true,
                                            }
                                        })
                                        this.changeLang(this.state.changeLangInELearning.lang);
                                    }}
                                    onCancel={() => {
                                        this.setState({
                                            changeLangInELearning: {
                                                ...this.state.changeLangInELearning,
                                                modalVisible: false
                                            }
                                        })
                                    }}
                                >
                                    <h3>{t('common.attention')}</h3>
                                    <b>{t('e_learning.if_language_changed_module_rest')}</b>
                                </Modal>
                                {
                                    agreementLoaded || noNeedAgreement || !this.state.hasTheory
                                        ? noNeedAgreement || approvedAgreement || !this.state.hasTheory
                                            ? <>
                                                {this.state.showSider
                                                    ? <Sider breakpoint={'lg'} collapsedWidth={0} style={{ position: "fixed", zIndex: 100, height: "100vh", overflow: this.state.isMobile ? "none" : "auto" }} width={270}>
                                                        <div>
                                                            <div className="logo" style={{ display: "flex", alignItems: "center" }}>
                                                                Einšteins
                                                                {
                                                                    this.state.real_elearning
                                                                        ? <></>
                                                                        : <div className='flags'>
                                                                            {
                                                                                this.state.languages.map((lang: any) => {
                                                                                    const iso = lang.iso === "en" ? "gb" : lang.iso;
                                                                                    return <button
                                                                                        key={lang.id}
                                                                                        onClick={() => this.changeLang(lang.iso, "from_button")}
                                                                                    >
                                                                                        <Flag
                                                                                            key={lang}
                                                                                            basePath={`${process.env.REACT_APP_API_URL}storage/flags`}
                                                                                            format="svg"
                                                                                            name={iso}
                                                                                            className={i18n.language === lang.iso ? 'active' : ''}
                                                                                        />
                                                                                    </button>
                                                                                })
                                                                            }
                                                                        </div>
                                                                }
                                                                <Link to="/settings">
                                                                    <Button
                                                                        type='text'
                                                                        title="Settings"
                                                                    >
                                                                        <SettingOutlined
                                                                            style={{
                                                                                color: "#FFFFFF",
                                                                                fontSize: 16
                                                                            }}
                                                                        />
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                            <BalanceCard self={self} balance={this.state.balance} credit={this.state.credit} bonus={this.state.bonus} />
                                                            {
                                                                Object.keys(self).length > 0
                                                                && <TutorialModal
                                                                    read={!!self?.read_help}
                                                                />
                                                            }
                                                            <Route path="/" render={({ location }) => {
                                                                const menuLinksNew = menuLinksPremade.map((link: any) => {
                                                                    const allowTests = !!this.state.self?.category?.has_test_subscription
                                                                        && this.state.self?.category?.test_languages?.some((el: any) => el === this.state.self?.language_id)
                                                                    if (!this.state.hasTheory && link.to === "learn-type") return undefined;
                                                                    if (link.to === 'learn-type') {
                                                                        if (!hardReg) return undefined;
                                                                        if (!canStartE) return undefined;
                                                                        link.to = this.state.eLearning ? "/e-learning" : "/theory"
                                                                        link.title = this.state.eLearning ? `${t('navigation.e_learning')}` : `${t('navigation.theory')}`
                                                                    }
                                                                    if (link.to === '/agreements' && !this.state.checkForAgreement) return undefined;
                                                                    if (link.to === '/extra-services' && !activeGroup || activeGroup === undefined) return undefined;
                                                                    if (link.to.includes('/tests') && !allowTests) return undefined;
                                                                    return link;
                                                                }).filter((item: any) => item !== undefined);
                                                                const selectedKeys = menuLinksNew
                                                                    .map((link: any, index: any) => {
                                                                        if (!link) return;
                                                                        return link.hasOwnProperty("submenu")
                                                                            ? link.submenu?.map((sub: any, idx: any) =>
                                                                                matchPath(location.pathname, { path: sub.to })
                                                                                    ? `${index}${idx}${101}`//String(index + idx * 3)
                                                                                    : null
                                                                            ).filter((value: any) => typeof value === 'string')[0] :
                                                                            matchPath(location.pathname, { path: link.to })
                                                                                ? String(index + 1)
                                                                                : null
                                                                    }).filter((value: any) => typeof value === 'string');
                                                                return (
                                                                    <Menu theme="dark" mode="inline" selectedKeys={selectedKeys as any}>
                                                                        {menuLinksNew.map((link: any, index: any) => {
                                                                            if (!link) return;
                                                                            return link.hasOwnProperty('submenu')
                                                                                ? <Menu.SubMenu
                                                                                    icon={link.submenu.map((item: any, idx: any) => `${index}${idx}${101}`).some((el: any) => el === selectedKeys[0]) ? link.iconSelected : link.icon}
                                                                                    key={index + 1}
                                                                                    title={link.title}
                                                                                >
                                                                                    {link.submenu?.map((sub: any, idx: any) => {
                                                                                        if (sub.to === `/driving/stats` && !!!this.state.self.category.full_eval) return undefined;
                                                                                        return <Menu.Item key={`${index}${idx}${101}`}>
                                                                                            <Link to={sub.to}>{sub.title}</Link>
                                                                                        </Menu.Item>
                                                                                    })}
                                                                                </Menu.SubMenu>
                                                                                : <Menu.Item icon={selectedKeys[0] === String(index + 1) ? link.iconSelected : link.icon} key={String(index + 1)}>
                                                                                    <Link to={link.to}>{link.title}</Link>
                                                                                </Menu.Item>
                                                                        }
                                                                        )}
                                                                    </Menu>
                                                                );
                                                            }}>
                                                            </Route>
                                                        </div>

                                                        <div className="bottom">
                                                            <Row>
                                                                <div style={{ padding: "20px 0", textAlign: "left" }}>
                                                                    <p><b className="student-name">{t("app.now_we_have_app")}</b></p>
                                                                    <a href="https://play.google.com/store/apps/details?id=com.einsteinsstudentapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                                                                        <img
                                                                            src={GetItOnGooglePlay}
                                                                            alt="get it on Play store"
                                                                            style={{ width: 140, marginBottom: 10 }}
                                                                        />
                                                                    </a>
                                                                    <a href="https://apps.apple.com/lv/app/autoskola-ein%C5%A1teins/id6466618838" target="_blank" rel="noopener noreferrer">
                                                                        <img
                                                                            src={GetItOnAppStore}
                                                                            alt="get it on App store"
                                                                            style={{ width: 140 }}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </Row>
                                                            <b className="student-name">{this.state.self.first_name} {this.state.self.last_name}</b>
                                                            <div style={{ marginBottom: 10 }}>
                                                                {
                                                                    this.state.studentCategories?.map((stCat: any) => {
                                                                        const active = stCat.category_id === this.state.currentCategory
                                                                        return <Button
                                                                            className='btn ghost'
                                                                            style={{
                                                                                display: "inline-block",
                                                                                margin: "0 5px 5px",
                                                                                backgroundColor: active ? "#079dc1" : "unset"
                                                                            }}
                                                                            onClick={() => this.setActiveCategory(stCat.category_id)}
                                                                        >
                                                                            {this?.state?.categories?.find((el: any) => el.id === stCat.category_id)?.value}
                                                                        </Button>
                                                                    })
                                                                }
                                                            </div>
                                                            <a
                                                                className='btn ghost'
                                                                style={{ marginBottom: 10 }}
                                                                href={`${process.env.REACT_APP_API_URL}storage/student-portal-help/sp-help.pdf`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {t('common.help')}
                                                            </a>
                                                            <a className="btn" href="/#logout" onClick={this.logout} >{t('common.exit')}</a>
                                                        </div>
                                                        {
                                                            this.state.eLearning
                                                            && <MandatoryDataModal
                                                                student={this.state.self}
                                                            />
                                                        }
                                                    </Sider>
                                                    : <></>
                                                }
                                                <Switch>
                                                    <Route path="/settings">
                                                        <NotificationSettings student={this.state.self} />
                                                    </Route>
                                                    <Route path="/planner">
                                                        <PlannerPage self={this.state.self} refreshBalance={() => this.fetchBalance()} />
                                                    </Route>
                                                    <Route path="/driving">
                                                        <DrivingPage self={this.state.self} refreshBalance={() => this.fetchBalance()} />
                                                    </Route>
                                                    <Route path="/agreements">
                                                        <div style={{ margin: "30px 30px 30px 300px", padding: 60, backgroundColor: "#FFFFFF", width: "100%" }}>
                                                            <Agreements
                                                                student={this.state.self}
                                                                agreement={this.state.agreement}
                                                                noControls
                                                            />
                                                        </div>
                                                    </Route>
                                                    <Route path="/payments">
                                                        <StudentPaymentsPage self={this.state.self} />
                                                    </Route>
                                                    <Route path="/extra-services">
                                                        <ExtraServicesMain self={this.state.self} refreshBalance={() => this.fetchBalance()} />
                                                    </Route>
                                                    <Route exact path="/">
                                                        <Redirect to="/planner" />
                                                    </Route>
                                                    <Route path="/e-learning/:id/:mid/:cid">
                                                        {
                                                            (hardReg && canStartE && this.state.eLearning)
                                                            && <LessonLayout self={this.state.self} />
                                                        }
                                                    </Route>
                                                    <Route path="/e-learning/bookmarks">
                                                        {
                                                            (hardReg && canStartE && this.state.eLearning)
                                                            && <BookmarksView self={this.state.self} />
                                                        }
                                                    </Route>
                                                    <Route path="/e-learning/questions">
                                                        {
                                                            (hardReg && canStartE && this.state.eLearning)
                                                            && <MyQuestions />
                                                        }
                                                    </Route>
                                                    <Route self={self} path="/e-learning/exam/:mid">
                                                        {
                                                            (hardReg && canStartE && this.state.eLearning)
                                                            && <ExamStartPage self={this.state.self} />
                                                        }
                                                    </Route>
                                                    <Route exact path="/e-learning">
                                                        {
                                                            (hardReg && canStartE && this.state.eLearning)
                                                            && <ELearningLayout refetchBalance={() => this.fetchBalance()} self={this.state.self} />
                                                        }
                                                    </Route>
                                                    <Route path="/exam-instance">
                                                        {
                                                            (hardReg && canStartE && this.state.eLearning)
                                                            && <ExamPage isOpen={(value: boolean) => this.setState({ showSider: !value })} self={this.state.self} />
                                                        }
                                                    </Route>
                                                    <Route path="/theory">
                                                        {
                                                            !this.state.eLearning && <TheoryMain self={this.state.self} refetchBalance={() => this.fetchBalance()} />
                                                        }
                                                    </Route>
                                                    <Route path="/theory-exam/:hash">
                                                        <TheoryExamStartPage self={this.state.self} />
                                                    </Route>
                                                    <Route path="/theory-exam-instance">
                                                        <TheoryExamPage isOpen={(value: boolean) => this.setState({ showSider: !value })} self={this.state.self} />
                                                    </Route>
                                                    <Route path="/theory-exams">
                                                        {
                                                            (!!this.state.self?.got_theory_finished
                                                                && !!!this.state.self?.got_theory_exam_finished
                                                                && !!this.state.self?.got_pmp
                                                                && !!this.state.self?.got_medical
                                                                && !!!this.state.self?.expired_theory
                                                                && (this.state.self?.csdd_medical_categories && this.state.self?.csdd_medical_categories?.some((el: any) => el === this.state.self?.category?.value))
                                                            )
                                                            && <TheoryExamsPlanner />
                                                        }
                                                    </Route>
                                                    <Route path="/einstein-coins">
                                                        <CoinsLayout refreshBalance={() => this.fetchBalance()} bonus={this.state.bonus} student={this.state.self} />
                                                    </Route>
                                                    <Route path="/notifications">
                                                        <NotificationsLayout refreshBalance={() => this.fetchBalance()} />
                                                    </Route>
                                                    <Route path="/tests/start-test/:instance">
                                                        {
                                                            !!this.state?.self?.category?.has_test_subscription
                                                            && <TestInstancePage
                                                                isOpen={(value: boolean) => this.setState({ showSider: !value })}
                                                                languages={this.state.languages}
                                                                student={this.state.self}
                                                            />
                                                        }
                                                    </Route>
                                                    <Route path="/tests/start-test">
                                                        {
                                                            !!this.state?.self?.category?.has_test_subscription
                                                            && <StartTestPage
                                                                isOpen={(value: boolean) => this.setState({ showSider: !value })}
                                                                languages={this.state.languages}
                                                                student={this.state.self}
                                                                refreshBalance={() => this.fetchBalance()}
                                                            />
                                                        }
                                                    </Route>
                                                    <Route path="/tests">
                                                        {
                                                            !!this.state?.self?.category?.has_test_subscription
                                                            && <TestsMainPageLayout student={this.state.self} />
                                                        }
                                                    </Route>
                                                    <Route path="/contacts">
                                                        <ContactsScreen />
                                                    </Route>
                                                </Switch>
                                            </>
                                            : <div style={{ margin: 80, padding: 60, backgroundColor: "#FFFFFF", width: "100%" }}>
                                                <div
                                                    className='bottom'
                                                    style={{
                                                        marginBottom: 10,
                                                        backgroundColor: "rgb(180 179 182)",
                                                        padding: "8px 0",
                                                        borderRadius: 4,
                                                        textAlign: "right"
                                                    }}
                                                >
                                                    {
                                                        this.state.studentCategories?.map((stCat: any) => {
                                                            const active = stCat.category_id === this.state.currentCategory
                                                            return <Button
                                                                className='btn ghost'
                                                                style={{
                                                                    display: "inline-block",
                                                                    margin: "0 5px",
                                                                    backgroundColor: active ? "#079dc1" : "unset"
                                                                }}
                                                                onClick={() => this.setActiveCategory(stCat.category_id)}
                                                            >
                                                                {this?.state?.categories?.find((el: any) => el.id === stCat.category_id)?.value}
                                                            </Button>
                                                        })
                                                    }
                                                </div>
                                                <Agreements
                                                    student={this.state.self}
                                                    agreement={this.state.agreement}
                                                    logout={this.logout}
                                                />
                                            </div>
                                        : <Spin style={{ margin: "auto" }} />
                                }
                            </>
                    }
                </Layout>
            </ConfigProvider>
        );

        const onLogin = async ({ email, password }: any) => {
            this.setState({ loginError: false })
            try {
                const result = await http.post("login", { email, password });
                const bearer_token = result.data.data.api_token;
                if (bearer_token != null) {
                    sessionStorage.setItem("bearer_token", bearer_token);
                    window.location.href = "/";
                } else {
                    throw new Error();
                }
            } catch (e) {
                console.error("Login failed");
                this.setState({
                    loginError: true
                })
            }
        };

        const loginLayout = <LoginPage error={this.state.loginError} onLogin={onLogin} />

        let out = null;
        if (this.state.loaded) {
            if (this.state.login && window.location.href.includes('web-test')) {
                out = <WebCsnTestsDemo
                    languages={this.state.languages}
                    student={this.state.self}
                />
            }
            else if (this.state.login || window.location.href.includes('slot-opportunity')) {
                out = loginLayout
            } else {
                out = normalLayout;
            }
        }

        return <Router>
            <div className="App">
                {out}
            </div>
        </Router>
    }
}

export default withTranslation()(App);
