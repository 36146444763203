import { Button, Card, Checkbox, Col, Collapse, Input, Layout, Modal, Row, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { http } from "../../helpers/http";
import { LessonPlayer } from "./video/LessonPlayer";
import { ReactComponent as IconFile } from "../../img/ic-file-24px.svg"
import { ReactComponent as IconAsk } from "../../img/ic-ask-question.svg"

import { ReactComponent as IcBookmark } from '../../img/ic-cc-1.svg';
import { ChapterNavigation } from "./components/ChapterNavigation";
import { BookmarkTitleCard } from "./BookmarkTitleCard";
import { ElearningHeader } from "./components/ElearningHeader";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { LessonRestriction } from "./LessonRestriction";
import { AskLecturerModal } from "./components/AskLecturerModal";
import ReactPlayer from "react-player";
import { AxiosResponse } from "axios";

export const LessonLayout = withRouter((props: any) => {
    const { t } = useTranslation();
    const { self, doneModule } = props;
    const history = useHistory();

    const { cid, mid, id } = useParams() as any;
    // const rawUrl = process.env.REACT_APP_API_URL + `student-api/get-video?e_chapter_id=${cid}&video_quality=medium&language_id&bearrer_token&verified`
    const [languages, setLanguages] = useState([]);
    const [rawUrl, setRawUrl] = useState(process.env.REACT_APP_API_URL + `student-api/get-video?e_chapter_id=${cid}&video_quality=medium&language_id&bearrer_token&verified`)
    const [loading, setLoading] = useState(true);
    const [bookmarks, setBookmarks] = useState([] as any);
    const [token, setToken] = useState("" as any);
    const [verification, setVerification] = useState("" as any);
    const [url, setUrl] = useState(rawUrl as any);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [videoID, setVideoID] = useState(null as any);
    // const [data, setData] = useState({} as any);
    const [question, setQuestion] = useState(undefined as any);
    const [chapters, setChapters] = useState([] as any);
    const [chapter, setChapter] = useState({} as any);
    const [module, setModule] = useState({} as any)
    const [chapterIsDone, setChapterIsDone] = useState(false);
    const [askLecturerVisible, setAskLecturerVisible] = useState(false);
    const [time, setTime] = useState(props.location.state?.time || undefined);
    const [allowedToday, setAllowedToday] = useState(true);
    const [allowedThisWeek, setAllowedThisWeek] = useState(true);
    const [moduleIsFailed, setModuleIsFailed] = useState(false);
    const [qualities, setQualities] = useState([] as any);
    const [stopVideo, setStopVideo] = useState(false);
    const [videoTime, setVideoTime] = useState("" as any);
    const [errorRecovery, setErrorRecovery] = useState(false);

    const setErrorResolved = (error: number) => {
        http.post(`set-chapter-video-resolved/${error}`, { resolved: 1 })
    }

    const fetchHadErrors = () => {
        http.get(`get-chapter-video-error/${chapter.id}`).then((response: AxiosResponse) => {
            if (!!response.data) {
                Modal.confirm({
                    title: "Atrasts ieraksts",
                    content: "Iepriekšējā skatīšanās reizē bija problēmas ar šo video. Vai vēlaties turpināt no tās vietas, kurā palikāt?",
                    okText: t('common.yes'),
                    cancelText: t('common.no'),
                    onOk: () => {
                        setErrorRecovery(true);
                        setTime(response.data?.error_time + 1);
                        setErrorResolved(response.data.id);
                    },
                    onCancel: () => setErrorResolved(response.data.id)
                })
            }
        })
    }

    const fetchLanguages = async () => {
        await http.get(`languages`).then((response: any) => {
            setLanguages(response.data.data);
        })
    }

    const fetchToken = () => {
        const tokenData = http.get(`/get-video-token`).then((response: any) => {
            return http.post('/token/verify', response.data.token.token).then((resp: any) => {
                setToken(response.data.token.token);
                setVerification(resp.data.verified);
                setTimeout(() => setVerification(""), 18000000);
                return {
                    token: response.data.token.token,
                    verification: resp.data.verified
                }
            })
        })
        return tokenData;
    };

    const getSecretStuff = () => {
        http.get(`/get-video-token`, {
            params: {
                bearrer_token: token,
                check: token.split("").reverse().join("")
            }
        }).then((response: any) => {
            setVideoID(response.data.token.video)
        })
    }

    const fetchLimits = async () => {
        // const dayLimit = await http.get(`/get-module-day-limit`).then((response: any) => {
        //     return (response.data.data)
        // });
        const doneToday = await http.get(`/check-module-day-limit`).then((response: any) => {
            return (response.data.legit)
        });
        // const weekLimit = await http.get(`/get-module-week-limit`).then((response: any) => {
        //     return (response.data.data)
        // });
        const doneThisWeek = await http.get(`/check-module-week-limit`).then((response: any) => {
            //console.log(response)
            return (response.data.legit)
        });
        setLoading(false);
        if (chapterIsDone) return;
        setAllowedToday(!!doneToday && !!doneThisWeek);
        setAllowedThisWeek(!!doneThisWeek);
    }

    const fetchBookmarks = () => {
        http.get(`/bookmarks/${self.id}`, { params: { chapter: cid } } as any).then((response: any) => {
            const bookmarks = Object.keys(response.data.data).map((key: any) => {
                return response.data.data[key]
            })
            //console.log(bookmarks)
            setBookmarks(bookmarks)
        });
    };

    const fetchQuestion = () => {
        http.get(`/get-chapter-question/${cid}`).then((response: any) => {
            setQuestion({ ...response.data.question, question_time: response.data.question !== null ? response.data.question_time : null });
        });
    };

    const fetchData = () => {
        http.get(`learning-modules-list/${id}/${mid}`).then((response: any) => {
            http.get(`/student-modules/${self?.id}`).then((record: any) => {
                const module = record.data.data.find((el: any) => {
                    return el.id === response.data.data.id
                })
                if (module.failed_exam) {
                    setModuleIsFailed(true);
                    return;
                }
            })
            const chapters = response.data.data?.language_chapters;
            const chapter = chapters.find((chapter: any) => chapter.hash_id === cid);
            setModule(response.data.data);
            setChapters(chapters);
            setChapter(chapter);
        });
        http.get(`/student-chapters/${self.id}`).then((response: any) => {
            //console.log(response);
            const { data: passed } = response.data;
            const isDone = passed.some((el: any) => el.hash_id === cid && el.finished === 1);
            if (isDone) {
                setChapterIsDone(true);
            }
        });
    };

    const downloadAttachment = (attachment: any) => {
        http.get(`/download-learning-attachment/${attachment.file}/${attachment.original_name}`, { responseType: 'blob' })
            .then((response: any) => {
                return new Blob([response.data]);
            }).then((blob: any) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a') as any;
                link.href = url;
                link.setAttribute('download', attachment.original_name);
                document.body.appendChild(link);
                link.click();
                if (link !== null) link.parentNode.removeChild(link);
            })
    };

    const setChapterProgress = (time: any, duration: any) => {
        if (chapterIsDone) return;
        postChapterProgress(time);
        if (time >= (duration - 3)) {
            setChapterDone();
        }
    };

    const setChapterDone = () => {
        http.post(`/set-passed-chapter`, { chapter: cid }).then((response: any) => {
            if (response.data.data.finished === 1) setChapterIsDone(true);
        })
    };

    const postChapterProgress = (time: any = 0) => {
        http.post('/set-chapter-progress', { chapter: cid, second: time }).then((response: any) => {
            if (!!module.is_pmp) {
                setChapterDone();
            }
        });
    }

    const goToNextChapter = async () => {
        const url = chapter.ind === chapters?.length - 1
            ? module.exam_questions_count === 0
                ? '/e-learning'
                : `/e-learning/exam/${chapter?.module_hash_id}`
            : `/e-learning/${self.category_id}/${chapters[chapter.ind + 1].module_hash_id}/${chapters[chapter.ind + 1].hash_id}`
        // if (module.exam_questions_count === 0 && chapter.ind === chapters?.length - 1) {
        //     setModuleDone();
        // }
        window.location.href = url
    }

    const setModuleDone = async () => {
        await http.post(`/set-passed-module/${module.hash_id}`, { skip: 1 });
        return true;
    }

    const fetchQualities = () => {
        if (languages.length === 0 || qualities.length > 0) return;
        //console.log(chapter)
        const language = languages.find((el: any) => {
            return el.iso === i18n.language
        }) as any;
        http.get(`get-video-quality`, {
            params: {
                e_chapter_id: chapter.hash_id,
                language_id: language.id
            }
        }).then((res: any) => {
            //console.log(res)
            setQualities(res.data.qualities)
        })
    };

    const changeUrlParam = async (params: any) => {
        let newUrl = url;
        let newVerification;
        let parameters = params;
        if (verification.length === 0) {
            await fetchToken().then((data: any) => {
                newVerification = {
                    bearrer_token: data.token,
                    verified: data.verification
                }
                parameters = {
                    ...parameters,
                    ...newVerification
                }
            })
        }
        Object.keys(parameters).forEach((key: any) => {
            newUrl = newUrl.split("&").map((el: any) => {
                if (el.includes(key)) {
                    return `${key}=${parameters[key]}`
                }
                return el
            }).join("&");
        });
        setUrl(newUrl);
    };

    useEffect(() => {
        fetchData();
        // fetchToken();
        fetchBookmarks();
        fetchQuestion();
        fetchLimits();
        fetchLanguages();
    }, []);

    useEffect(() => {
        if (chapter.hasOwnProperty('id')) {
            fetchHadErrors();
        }
    }, [chapter]);

    useEffect(() => {
        if (module) {
            if (!!module.is_pmp && !chapterIsDone) {
                postChapterProgress()
            }
        }
    }, [module]);

    useEffect(() => {
        if (languages.length > 0) {
            const lang = languages.find((el: any) => {
                return el.iso === i18n.language;
            }) as any;
            changeUrlParam({ language_id: lang.id })
        };
    }, [languages]);

    useEffect(() => {
        if (videoLoaded) {
            getSecretStuff();
            fetchQualities();
        }
    }, [videoLoaded])

    useEffect(() => {
        if (chapterIsDone) {
            setAllowedThisWeek(true);
            setAllowedToday(true);
            if (chapter.ind === chapters?.length - 1 && module.exam_questions_count === 0) {
                setModuleDone()
            }
        }
    }, [chapterIsDone]);

    useEffect(() => {
        setStopVideo(askLecturerVisible)
    }, [askLecturerVisible]);

    return loading
        ? <Layout className="main-content"><Skeleton /></Layout>
        : <Layout className="main-content">
            <ElearningHeader />
            {
                moduleIsFailed
                    ? <LessonRestriction status={t('e_learning.module_failed_wait_for_payment')} />
                    : (allowedThisWeek && allowedToday) || chapterIsDone || !!module.is_pmp
                        ? <>
                            <Card className="e-learning-card">
                                <h2>{chapter?.[`title_${i18n.language}`]}</h2>
                                <p>{chapter?.[`description_${i18n.language}`]}</p>
                                {
                                    !!module.is_pmp
                                        ? <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: "50vw",
                                                    width: "70vw",
                                                    maxHeight: "80vh"
                                                }}
                                            >
                                                <ReactPlayer
                                                    url={chapter?.[`yt_url_${i18n.language}`]}
                                                    controls={true}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                        </div>
                                        : <LessonPlayer
                                            bookmarksList={bookmarks}
                                            url={url}
                                            videoID={videoID}
                                            loaded={setVideoLoaded}
                                            refreshBookmarks={fetchBookmarks}
                                            jumpTo={time}
                                            errorRecovery={errorRecovery}
                                            resetTime={() => setTime(undefined)}
                                            question={question}
                                            setProgress={(time: any, duration: any) => setChapterProgress(time, duration)}
                                            done={chapterIsDone}
                                            goToNextChapter={goToNextChapter}
                                            lesson
                                            changeQuality={(quality: any) => changeUrlParam({ video_quality: quality })}
                                            qualities={qualities}
                                            stopVideo={stopVideo}
                                            videoTime={(time: any) => setVideoTime(time)}
                                        />
                                }
                                {
                                    chapter?.sorted_attachments?.[`attachment_${i18n.language}`]?.length > 0
                                        ? <div className="attachments">
                                            <p>{t('e_learning.chapter_attachments')}</p>
                                            {
                                                chapter?.sorted_attachments?.[`attachment_${i18n.language}`]?.map((attachment: any) => {
                                                    return <div key={attachment.id} className="file-card">
                                                        <div className="icon-wrap">
                                                            <IconFile />
                                                        </div>
                                                        <div className="file-info-wrap">
                                                            <span
                                                                onClick={() => downloadAttachment(attachment)}
                                                            >
                                                                {attachment.original_name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        : <></>
                                }
                                {
                                    bookmarks?.length > 0
                                        ? <>
                                            <Row justify="space-between" align="bottom">
                                                <h2 style={{ marginTop: 45, marginBottom: 21 }}>{t('e_learning.my_bookmarks')}</h2>
                                                <Button
                                                    type="text"
                                                    style={{ display: "flex", marginBottom: 19, color: "#059DC1" }}
                                                    onClick={() => history.push('/e-learning/bookmarks')}
                                                >
                                                    <IcBookmark className="ic-bookmark-round" style={{ marginRight: 10 }} />
                                                    {t('e_learning.open_all_bookmarks')}
                                                </Button>
                                            </Row>
                                            <Card>
                                                {
                                                    bookmarks?.map((bookmark: any, index: any) => {
                                                        return <BookmarkTitleCard
                                                            self={self}
                                                            bookmark={bookmark}
                                                            key={index}
                                                            refreshBookmarks={fetchBookmarks}
                                                            inLesson
                                                            jumpTo={(time: any) => setTime(time)}
                                                        />
                                                    })
                                                }
                                            </Card>
                                        </>
                                        : <></>
                                }
                                <Row justify="space-between" style={{ marginTop: 24 }} wrap={false}>
                                    <Col>
                                        {
                                            chapter.ind > 0
                                                ? <ChapterNavigation
                                                    chapter={chapters[chapter.ind - 1]}
                                                    category={self.category_id}
                                                    previous
                                                />
                                                : <></>
                                        }
                                    </Col>
                                    <Col>
                                        {
                                            chapter.ind === chapters?.length - 1 && !doneModule && module.exam_questions_count > 0
                                                ? <ChapterNavigation
                                                    chapter={chapter}
                                                    category={self.category_id}
                                                    next
                                                    disabled={!chapterIsDone}
                                                    exam
                                                />
                                                : chapter.ind < chapters?.length - 1
                                                    ? <ChapterNavigation
                                                        chapter={chapters[chapter.ind + 1]}
                                                        category={self.category_id}
                                                        next
                                                        disabled={!chapterIsDone}
                                                    />
                                                    : module.exam_questions_count === 0
                                                        ? <ChapterNavigation
                                                            chapter={undefined}
                                                            category={self.category_id}
                                                            module={module}
                                                            next
                                                            disabled={!chapterIsDone}
                                                        />
                                                        : <></>
                                        }
                                    </Col>
                                </Row>
                            </Card>
                            {
                                chapter?.faqs?.length > 0
                                    ? <div style={{ marginBottom: 20 }}>
                                        <h2>{t('e_learning.faq')}</h2>
                                        <p>{t('e_learning.suggestion_to_repeat_lessons')}</p>
                                        {chapter.faqs.map((faq: any, index: any) => {
                                            return <div className="faq-collapse" key={index} style={{ marginBottom: 4 }}>
                                                <Collapse
                                                    expandIconPosition="right"
                                                >
                                                    <Collapse.Panel key="1" header={<b>{faq[`question_${i18n.language}`]}</b>}>
                                                        <p>{faq[`answer_${i18n.language}`]}</p>
                                                    </Collapse.Panel>
                                                </Collapse>
                                            </div>
                                        })}
                                    </div>
                                    : <></>
                            }
                            <Card>
                                <Row justify="space-between" align="middle">
                                    <div>
                                        <Row align="middle">
                                            <IconAsk />
                                            <div style={{ marginLeft: 14 }}>
                                                <b>{t('e_learning.have_questions')}</b>
                                                <p style={{ margin: 0 }}>{t('e_learning.ask_lecturer_your_question')}</p>
                                            </div>
                                        </Row>
                                    </div>
                                    <button
                                        className="btn-blue ask-lecturer-btn"
                                        onClick={() => setAskLecturerVisible(true)}
                                    >
                                        {t('e_learning.ask_lecturer')}
                                    </button>
                                    <AskLecturerModal
                                        askLecturerVisible={askLecturerVisible}
                                        setAskLecturerVisible={(state: any) => setAskLecturerVisible(state)}
                                        self={self}
                                        videoTime={videoTime}
                                        chapterID={chapter.id}
                                    />
                                </Row>
                            </Card>
                        </>
                        : !allowedThisWeek
                            ? <LessonRestriction status={t('e_learning.this_week_limit_exceeded')} />
                            : !allowedToday
                                ? <LessonRestriction status={t('e_learning.this_day_limit_exceeded')} />
                                : <></>
            }
        </Layout>
})