import axios, { AxiosResponse } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import i18n from "i18next";
import { Card, Layout, message } from "antd";
import { useTranslation } from "react-i18next";
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

export const ContactsScreen = () => {
    const { t } = useTranslation();
    const [admin, setAdmin] = useState<any>(null);

    const getContacts = () => {
        axios.get('https://einsteins.lv/api/sys/page/contact?key=f91fd1b9-9c06-4156-ba66-e9e137d8317b').then((res: AxiosResponse) => {
            if (res.data) {
                const clientAdmin = res.data.find((item: any) => {
                    return item.locale === i18n.language
                })
                setAdmin(clientAdmin);
            }
        }).catch((err) => {
            message.error(t('errors.error'));
        })
    };

    useEffect(() => {
        getContacts();
    }, [i18n.language]);

    return <Layout className="main-content">
        <Card
            title={t('app.contacts')}
            style={{ marginBottom: 20 }}
        >
            {
                admin
                && <div>
                    <h3 style={{ marginBottom: 20 }}>{admin?.contact_person_subtext}</h3>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ fontWeight: "bold" }}><PhoneOutlined style={{ marginRight: 8 }} /><a href={`tel:${admin?.phone}`}>{admin?.phone}</a> (Tālr. un Whatsapp)</p>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ fontWeight: "bold" }}><MailOutlined style={{ marginRight: 8 }} /><a href={`mailto:${admin?.email}`}>{admin?.email}</a></p>
                    </div>
                </div>
            }
        </Card>
    </Layout>
};